import React from 'react'

import { Container } from '../../../components'

export default () => (
  <div id="hero">
    <div class="opacity">
      <Container>
        <div className="row">
          <div className="col text-center">
            <h1 className="hero-title"><strong>DESENVOLVEMOS SOLUÇÕES</strong></h1>
            <h3>que impactam milhares de vidas todos os dias</h3>
          </div>
        </div>
      </Container>
    </div>
  </div>
)