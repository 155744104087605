import React from 'react'

import { Container } from '../../../components'

export default () => (
  <div id="header">
    <Container>
      <img
        src="/appolus-logo.png"
        alt="Logo Appolus Tecnologia"
        className='logo'
      />
    </Container>
  </div>
)
