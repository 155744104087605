import React from 'react'

import { Container } from '../../../components'

export default () => (
  <div id="privacy-policies" className="py-5">
    <Container>
      <h4 className="mb-4">Políticas de privacidade</h4>
      <p className="mb-4">
        Aqui você pode consultar as políticas de privacidade de nossos
        aplicativos e sistemas
      </p>
      <div className='row'>
        <div className='col-md-6'>
          <h5>Aplicativos</h5>
          <ul className="mb-0">
            <li>
              <a href="/soucidadao/politicas-de-privacidade">souCidadão</a>
            </li>
            <li>
              <a href="/soudocente/politicas-de-privacidade">souDocente</a>
            </li>
            <li>
              <a href="/sougestor/politicas-de-privacidade">souGestor</a>
            </li>
            <li>
              <a href="/vida-escolar/politicas-de-privacidade">Vida Escolar</a>
            </li>
          </ul>
        </div>
        <div className='col-md-6'>
          <h5>Sistemas</h5>
          <ul className="mb-0">
            <li>
              <a href="/saude/politicas-de-privacidade">Saúde</a>
            </li>
            <li>
              <a href="/educacao/politicas-de-privacidade">Educação</a>
            </li>
          </ul>
        </div>
      </div>
    </Container>
  </div>
)
