import React from 'react'

import { Container } from '../../../components'

export default () => (
  <div id="about-us" className="py-5">
    <Container>
        <h4 className="mb-4">Sobre nós</h4>
        <h1 className="mb-4">
          Evoluímos com a tecnologia<br />
          em busca de soluções para o<br />
          desenvolvimento humano!
        </h1>
        <p>
          Somos uma empresa de desenvolvimento de software com mais de 10 anos de experiência.<br />
          Nosso foco é criar soluções simples para problemas complexos, acreditando que o<br />
          envolvimento profundo de pessoas capacitadas toma a solução prazerosa e direta.
        </p>
        <p>
          Nossos sistemas de saúde e educação são utilizados em várias localidades, impactando<br />
          diretamente a vida de mais de 1 milhão de usuários na área da saúde e milhares de alunos na<br />
          educação.
        </p>
        <p>
          Nos últimos 6 anos, tivemos pouquíssimas saídas de profissionais da empresa. Isso é resultado<br />
          de um ambiente saudável, relações positivas, compromisso cumprido e nosso objetivo de<br />
          crescimento.
        </p>
        <p>
          Nossos colaboradores são parte integrante da solução, participando ativamente de conversas<br />
          para compreender as regras e propor soluções, agregando suas experiências ao processo.
        </p>
        <p>
          Nossa empresa adotou o trabalho remoto há mais de 8 anos, realizando todo o processo de<br />
          criação de casa. Além disso, estamos abertos a novas tecnologias e implementações,<br />
          priorizando soluções que oferecem o melhor custo-benefício.
        </p>
        <p>
          Pautamos nossas ações pela honestidade, ética e transparência, mantendo relações comerciais<br />
          e pessoais baseadas na confiança e respeito mútuo.
        </p>
    </Container>
  </div>
)
