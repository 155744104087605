import React from 'react'

import { Container } from '../../../components'

export default () => {
  const currentYear = new Date().getFullYear();

  return (
    <div id="footer" className="py-5">
      <Container>
        <div className='row'>
          <div className="col">
            <p>
              <strong>Contatos</strong>
            </p>
            <p>
              WhatsApp: +55 31 97266-8650<br />
              E-mail: appolus@appolus.com.br
            </p>
          </div>
          <div className="col">
            <p>
              <strong>Siga-nos:</strong>
            </p>
            <p>
              <a
                href="https://www.linkedin.com/company/appolustecnologia"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
              <br />
              <a
                href="https://www.instagram.com/appolus.tec/"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
              <br />
              <a
                href="https://www.facebook.com/Appolus-Tecnologia-105255178610521"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </p>
          </div>
        </div>

        <div className='row'>
          <div className="col">
            <hr />
            <p>
              {`Todos os direitos reservados © ${currentYear} - Appolus Tecnologia Ltda - CNPJ 08.109.994/0001-90`}
            </p>
          </div>
        </div>
      </Container>
    </div>
  )
}
