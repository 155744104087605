import React from 'react'

import { Container } from '../../../components'

export default () => (
  <div id="mission" className="py-5">
    <Container>
        <h4 className="mb-4">Nossa missão</h4>
        <div className='row'>
          <div className='col-md-6'>
            <p className='pb-3'>
              <p>
                <strong className="topic p-2">Missão</strong>
              </p>
              Criar soluções simples e inovadoras para problemas complexos, utilizando metodologias ágeis e tecnologias avançadas,
              a fim de impactar positivamente a vida de milhares de pessoas, promovendo o crescimento pessoal e profissional de nossa equipe.
            </p>
            <p>
              <p>
                <strong className="topic p-2">Visão</strong>
              </p>
              Ser reconhecida como referência no desenvolvimento de software, destacando-se pela excelência em soluções simplificadas,
              pelo compromisso com o cliente e pela busca constante por inovação técnológica.
            </p>
          </div>
          <div className='col-md-6'>
            <p>
              <p>
                <strong className="topic p-2">Valores</strong>
              </p>
              <p><strong>- Excelência:</strong> buscamos superar expectativas, desde a concepção até a entrega final, visando excelência em todas as etapas</p>
              <p><strong>- Colaboração:</strong> valorizamos o trabalho em equipe, a comunicação aberta e o compartilhamento de conhecimento, promovendo um ambiente colaborativo</p>
              <p><strong>- Inovação:</strong> estamos abertos a novas tecnologias e ideias, estimulando a criatividade e a inovação para impulsionar nosso crescimento</p>
              <p><strong>- Aprendizado contínuo:</strong> estimulamos o desenvolvimento pessoal e profissional, promovendo a aprendizagem constante e o aprimoramento de habilidades</p>
            </p>
          </div>
        </div>
    </Container>
  </div>
)
