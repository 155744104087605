import React from 'react'

import { AboutUs, Development, Header, Hero, Mission, PrivacyPolicies, Footer } from './components'

export default () => (
  <>
    <Header />
    <Hero />
    <AboutUs />
    <Mission />
    <Development />
    <PrivacyPolicies />
    <Footer />
  </>
)
