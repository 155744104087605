import React from 'react'

import { Container } from '../../../components'

export default () => (
  <div id="development" className="py-5">
    <Container>
        <h4 className="mb-4">Tecnologia Appolus</h4>
        <div className='row'>
          <div className='col-lg-4 mb-4'>
            <div className='blue-card text-center p-3 d-flex align-items-center'>
              <p className="mb-0">
                <strong>O que a Appolus desenvolve?</strong>
                <br />
                <br />
                <small>
                  Somos especialistas no desenvolvimento de softwares e sistemas. Isso implica em criar soluções tecnológicas, como aplicativos e softwares,
                  que atendem às necessidades específicas de nossos clientes.<br />
                  A nossa principal função é simplificar e otimizar a rotina e o trabalho de diferentes setores, como áreas da saúde e educação.
                </small>
              </p>
            </div>
          </div>
          <div className='col-lg-4 mb-4'>
            <div className='blue-card text-center p-3 d-flex align-items-center'>
              <p className="mb-0">
                <strong>Como nos desenvolvemos?</strong>
                <br />
                <br />
                <small>
                  A Appolus utiliza uma abordagem profissional e experiente para desenvolver seus produtos. Seguimos as melhores práticas do desenvolvimento de software,
                  como análise detalhada dos requisitos, design de interfaces, programação, testes rigorosos para garantir a qualidade, e, por fim, a implantação das soluções.
                </small>
              </p>
            </div>
          </div>
          <div className='col-lg-4 mb-4'>
            <div className='blue-card text-center p-3 d-flex align-items-center'>
              <p className="mb-0">
                <strong>Pra quem nós desenvolvemos?</strong>
                <br />
                <br />
                <small>
                  A Appolus desenvolve soluções para diferentes setores. Porém, nosso foco principal são softwares para as áreas da saúde e educação. Desenvolvemos soluções
                  para melhorar processos e atender às necessidades específicas desses setores.
                </small>
              </p>
            </div>
          </div>
        </div>
    </Container>
  </div>
)
